﻿import '@/modules/Diagram.jQueryGlobal.js';
import { DiagramHasJS } from '@/modules/Diagram.hasJS.js';
import { DiagramAnalytics } from '@/modules/Diagram.Analytics.js'
import fitvids from '@/plugins/fitvids.js';
import DiagramBreakpoints from '@/modules/Diagram.Breakpoints.js';
import { DiagramSwiftypeAutoComplete } from '@/modules/Diagram.SwiftypeAutocomplete.js';
import '@/modules/Diagram.BodyLock.js';
import '@/plugins/iframe-resizer.parent.js';

iframeResize({ license: "1jy4dww5qzv-s54r73oxcn-v59f4kfgfz" }, '.iframe-resize');

DiagramHasJS();

if (typeof pageTracker == "undefined") {
    var pageTracker = {};
}

function customGAEvent(category, action, label) {
    try {
        pageTracker._trackEvent(category, action, label);
    } catch (e) {
        // console.log(e);
    }
}

// Items that can be processed before/after images/css complete
$(document).ready(async function ($) {
    await DiagramAnalytics();
    await fitvids();
    await DiagramSwiftypeAutoComplete();

    if ($('.js-main-nav').length) {
        const { DiagramSiteNavigation } = await import('@/modules/Diagram.SiteNavigation.js');
        await DiagramSiteNavigation();
    }

    if ($('.js-select-nav').length) {
        const { DiagramSelectNav } = await import('@/modules/Diagram.SelectNav.js');
        await DiagramSelectNav();
    }

    if ($('.js-accordion, .js-section-nav, .js-footer-nav, .dr-finder-accordion').length) {
        const { DiagramAccordions } = await import('@/modules/Diagram.Accordions.js');
        await DiagramAccordions();
    }

    if ($('.js-tabs:not(.tabcordions)').length) {
        const { DiagramTabs } = await import('@/modules/Diagram.Tabs.js');
        await DiagramTabs();
    }

    if ($('.js-tabs.tabcordions').length) {
        const { DiagramTabcordions } = await import('@/modules/Diagram.Tabcordions.js');
        await DiagramTabcordions();
    }

    if ($('.gallery--masonry, .js-synchronized-sliders').length) {
        const { DiagramGallery } = await import('@/modules/Diagram.Gallery.js');
        await DiagramGallery();
    }

    if ($('.js-scrolling-hero').length) {
        const { DiagramScrollingHero } = await import('@/modules/Diagram.ScrollingHero.js');
        await DiagramScrollingHero();
    }

    if ($('.js-content-carousel').length) {
        const { DiagramContentCarousel } = await import('@/modules/Diagram.ContentCarousel.js');
        await DiagramContentCarousel();
    }

    if ($('.js-scrollbar').length) {
        const { DiagramScrollbar } = await import('@/modules/Diagram.Scrollbar.js');
        await DiagramScrollbar();
    }

    if ($('.fancybox, .fancybox-media, .fancybox-modal, [data-fancybox]').length) {
        const { DiagramFancybox } = await import('@/modules/Diagram.Fancybox.js');
        await DiagramFancybox();
    }

    if ($('.js-page-select').length) {
        const { DiagramPagination } = await import('@/modules/Diagram.Pagination.js');
        await DiagramPagination();
    }

    if ($('.aa-wrap').length) {
        const { DiagramAppendAround } = await import('@/modules/Diagram.AppendAround.js');
        await DiagramAppendAround();
    }

    if ($('.alert-banners-wrapper').length) {
        const { DiagramAlertBanner } = await import('@/modules/Diagram.AlertBanner.js');
        await DiagramAlertBanner();
    }

    if ($('.sg-root').length) {
        const { DiagramStyleguide } = await import('@/modules/Diagram.Styleguide.js');
        await DiagramStyleguide();
    }

    if ($('.google-maps').length) {
        const { DiagramGoogleMaps } = await import('@/modules/Diagram.GoogleMaps.js');
        await DiagramGoogleMaps();
    }

    if ($('.dr-grid').length) {
        const { DiagramDrShowAll } = await import('@/modules/Diagram.DrShowAll.js');
        await DiagramDrShowAll();
    }

    if ($('.js-select-links').length) {
        const { DiagramSelectLinks } = await import('@/modules/Diagram.SelectLinks.js');
        await DiagramSelectLinks();
    }

    if ($('.js-dr-finder').length) {
        const { DiagramDrFinder } = await import('@/modules/Diagram.DrFinder.js');
        await DiagramDrFinder();
    }

    if ($('.js-tool-tip').length) {
        const { DiagramToolTip } = await import('@/modules/Diagram.ToolTip.js');
        await DiagramToolTip();
    }

    if ($('.wait-time-container').length) {
        const { DiagramWaitTimes } = await import('@/modules/Diagram.WaitTimes.js');
        await DiagramWaitTimes();
    }
   
   await DiagramBreakpoints();
});

// This occurs after the page is done loading.
$(window).on("load", function () {
    if (typeof _gat != "undefined") {
        //pageTracker = _gat._createTracker('UA-11858386-2');
    }

    $('[data-ga-track]').each(function () {
        var $this = $(this);
        if ($this.attr('data-ga-category') != undefined && $this.attr('data-ga-event') != undefined && $this.attr('data-ga-label') != undefined) {
            customGAEvent($this.attr('data-ga-category'), $this.attr('data-ga-event'), $this.attr('data-ga-label'));
        }
    });
});