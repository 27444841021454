﻿import "@/plugins/jquery.swiftype.autocomplete.js"

export async function DiagramSwiftypeAutoComplete() {
	$("input[data-searchType='st']").swiftype({
		engineKey: $("input[data-searchType='st']").attr('data-engineKey'),
		resultLimit: 5,
		renderFunction: function (document_type, item) {
			var title = item['title'];
			if (item['highlight']['title'] != undefined) {
				title = item['highlight']['title'];
			}
			//return '<a class="body-medium" href="' + item['url']  + '">' + title + '</a>';
			return '<a class="body-medium">' + title + '</a>';
		},
		setWidth: true,
		widgetContainerClass: 'swiftype-widget ac_results--new'
		//if($(this.element).data('autocomplete-full-width')) {
		//    this.menu.element.css('max-width', '100%');
		//}
	});
}